import React from 'react'
import styles from './index.module.scss'
import { FormattedMessage } from 'react-intl'

interface Props {
  invierno: boolean
  verano: boolean
  runflat: boolean
  allseason: boolean
}
export default function EstacionText({
  invierno,
  runflat,
  verano,
  allseason,
}: Props) {
  return (
    <div className={styles.estacion_wrapper}>
      {invierno ? (
        <div className={styles.estacion}>
          <FormattedMessage id="ficha-marca.invierno" />
        </div>
      ) : null}
      {verano ? (
        <div className={styles.estacion}>
          <FormattedMessage id="ficha-marca.verano" />
        </div>
      ) : null}
      {allseason ? (
        <div className={styles.estacion}>
          <FormattedMessage id="ficha-marca.todo-el-año" />
        </div>
      ) : null}
      {runflat ? (
        <div>
          <span className={styles.vh}>|</span>
          <span className={styles.runflat}>
            <FormattedMessage id="preferences.runflat" />
          </span>
        </div>
      ) : null}
    </div>
  )
}
