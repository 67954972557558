import React from 'react'
import { FormattedMessage } from 'react-intl'
import { selectMedida } from '../../../context/actions/selectorActions'
import { t } from '../../../../common/i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import NeumaticoMobile from '../../NeumaticoMobile/NeumaticoMobile'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'
import LoadingOptions from './LoadingOptions'

type Props = FunnelScreenProps
const FormSerie = ({
  dispatch,
  nextStep,
  selectorState,
  totalSteps,
}: Props) => {
  const onHandleClick = (value) => {
    dispatch(selectMedida({ name: 'serie', value }))
    nextStep()
  }

  if (
    !selectorState.serie.loading &&
    selectorState.serie.options.length === 0
  ) {
    dispatch(selectMedida({ name: 'ancho', value: selectorState.ancho.value }))
    return null
  }

  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={1}
      totalSteps={totalSteps}
      showSelectorFooter>
      <NeumaticoMobile selectorState={selectorState} actual="serie" />
      <div className={styles.title}>
        <FormattedMessage id="selector-mobile.perfil" />
      </div>
      {selectorState.serie.loading ? (
        <LoadingOptions />
      ) : (
        <form className={styles.options_container}>
          <ul className={styles.option_list_container}>
            {selectorState.serie.options &&
              selectorState.serie.options.map((option, index) => (
                <li
                  className={styles.option_list_item}
                  onClick={() => onHandleClick(option.value)}
                  key={index}>
                  {option.value}
                </li>
              ))}
          </ul>
        </form>
      )}
    </LayoutSelectorMobile>
  )
}

export default FormSerie
