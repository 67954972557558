import React from 'react'
import { FormattedMessage } from 'react-intl'
import { selectRunflat } from '../../../context/actions/selectorActions'
import { t } from '../../../../common/i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'

const options = [
  {
    value: false,
    key: 'no-runflat',
    label: 'preferences.no_runflat',
  },
  {
    value: true,
    key: 'runflat',
    label: 'preferences.runflat',
    description: 'preferences.form_runflat_description',
  },
]

interface Props extends FunnelScreenProps {}
const FormRunFlat = ({ dispatch, nextStep }: Props) => {
  const handleChangeRadioButton = value => {
    dispatch(selectRunflat(value))
    nextStep()
  }

  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={6}
      totalSteps={9}
      subTitle={t('preferences.runflat_title')}
      showSelectorFooter>
      <div className={styles.runflat_wrapper}>
        <form className={styles.options_container}>
          <ul className={styles.option_list_container}>
            {options.map((option, index) => (
              <li
                onClick={() => handleChangeRadioButton(option.value)}
                className={styles.option_list_item_estacion}
                key={index}>
                <div className={styles.estacion_title}>
                  <FormattedMessage id={option.label} />
                </div>
                {option.description && (
                  <div className={styles.estacion_subtitle}>
                    <FormattedMessage id={option.description} />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </form>
      </div>
    </LayoutSelectorMobile>
  )
}

export default FormRunFlat
