import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../assets/neumatico-selector.png'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { t } from '../../../../common/i18n'
import ModalInfoMedidas from '../Common/ModalInfoMedidas/ModalInfoMedidas'
import SelectorDropDown from '../Desktop/Components/SelectorDropDown'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import Step, { findNext, SelectorProps } from '../SelectorSteps'
import styles from './index.module.scss'

const SelectorMedidas = ({
  skipInfo,
  selectorState,
  handleChange,
  isValid,
}: SelectorProps) => {
  const [showModalMoreInfo, setShowModalMoreInfo] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (!isValid(selectorState)) {
      return
    }

    const next = findNext(Step.INITIAL, 'desktop', skipInfo)
    navigate(next.routes.desktop)
  }

  return (
    <section className={styles.sections}>
      <form onSubmit={handleSubmit}>
        <div className={styles.title}>
          <FormattedMessage id="selector-desktop.title" />
        </div>
        <div className={styles.form_container}>
          <div className={styles.firstRow}>
            <div className={styles.form_ancho}>
              <SelectorDropDown
                required
                size="xl"
                name="ancho"
                field={selectorState.ancho}
                onChange={handleChange}
                labelId="selector-desktop.ancho"
                labelAbove={true}
              />
              {selectorState.ancho.value && (
                <div className={styles.arrowAncho} />
              )}
              {selectorState.ancho.value && (
                <span className={styles.anchoSpan}>
                  {selectorState.ancho.value} /
                </span>
              )}
            </div>
            <div className={styles.form_serie}>
              <SelectorDropDown
                required
                size="xl"
                name="serie"
                field={selectorState.serie}
                onChange={handleChange}
                labelId="selector-desktop.serie"
                labelAbove={true}
              />
              {selectorState.serie.value && (
                <div className={styles.arrowSerie}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                  <div className={styles.thirdPart} />
                </div>
              )}
              {selectorState.serie.value && (
                <span className={styles.serieSpan}>
                  {selectorState.serie.value}
                </span>
              )}
            </div>
            <div className={styles.form_llanta}>
              <SelectorDropDown
                required
                size="xl"
                name="llanta"
                field={selectorState.llanta}
                onChange={handleChange}
                labelId="selector-desktop.llanta"
                labelAbove={true}
              />
              {selectorState.llanta.value && (
                <div className={styles.arrowLlanta}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                  <div className={styles.thirdPart} />
                </div>
              )}
              {selectorState.llanta.value && (
                <span className={styles.llantaSpan}>
                  R {selectorState.llanta.value}
                </span>
              )}
            </div>
          </div>

          <div className={styles.secondRow}>
            <img
              src={Neumatico}
              alt="Neumático genérico"
              width={450}
              height={133}
            />
            <div className={styles.ic_cv}>
              <SelectorDropDown
                required
                size="xl"
                name="carga"
                field={selectorState.carga}
                onChange={handleChange}
                labelId="selector-desktop.indice-carga"
                labelAbove={true}
                infoIconTitle={t('selector-desktop.indice-carga')}
                infoIconText={t('selector-desktop.indice_carga_help_text')}
              />
              {selectorState.carga.value && (
                <div className={styles.arrowIc}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                </div>
              )}
              {selectorState.carga.value && (
                <span className={styles.cargaSpan}>
                  {selectorState.carga.value}
                </span>
              )}
              <SelectorDropDown
                required
                size="xl"
                name="velocidad"
                field={selectorState.velocidad}
                onChange={handleChange}
                labelId="selector-desktop.indice-velocidad"
                labelAbove={true}
                infoIconTitle={t('selector-desktop.indice-velocidad')}
                infoIconText={t('selector-desktop.indice_velocidad_help')}
              />
              {selectorState.velocidad.value && (
                <div className={styles.arrowCv}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                </div>
              )}
              {selectorState.velocidad.value && (
                <span className={styles.velocidadSpan}>
                  {selectorState.velocidad.value}
                </span>
              )}
            </div>
          </div>
        </div>

        <ModalInfoMedidas
          showModalMoreInfo={showModalMoreInfo}
          setShowModalMoreInfo={setShowModalMoreInfo}
        />
        <SelectorFooterDesktop continueButton>
          <a
            href="#"
            className={styles.no_data}
            onClick={() => setShowModalMoreInfo(true)}>
            <FormattedMessage id="cesta.general.no_info" />
          </a>
        </SelectorFooterDesktop>
      </form>
    </section>
  )
}

export default SelectorMedidas
