import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  selectEstacion,
  selectMarca,
  selectRunflat,
  setMarcas,
} from '../../../context/actions/selectorActions'
import { t } from '../../../i18n'
import CheckBoxYellow from '../../checkboxYellow/CheckBoxYellow'
import InfoIcon from '../../infoIcon/InfoIcon'
import RadioButtonYellow from '../../radioButtonYellow/RadioButtonYellow'
import MarcasList from '../Desktop/MarcasList'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import styles from './selectorCaracteristicas.module.scss'
import { FunnelScreenProps } from '../SelectorSteps'
import withMarcas, { InjectedMarcas } from '../../withMarcas'
import { navigate } from '@reach/router'

interface Props extends FunnelScreenProps, InjectedMarcas {}
const SelectorCaracteristicas = ({
  dispatch,
  selectorState,
  previousStep,
  nextStep,
  marcas,
}: Props) => {
  const [showMarcasList, setShowMarcasList] = useState(false)

  useEffect(() => {
    dispatch(setMarcas(marcas))
  }, [])

  const onHandleRadioButtonChange = (event) => {
    if (event === 'marcas-list') {
      setShowMarcasList(true)
    } else {
      setShowMarcasList(false)
    }
  }

  const onMarcaSelect = (value) => {
    dispatch(selectMarca(value))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    nextStep()
  }

  const handleChangeEstacion = (name: 'allseason' | 'invierno') => {
    dispatch(selectEstacion(name))
  }

  const handleChangeRunflat = (e) => {
    dispatch(selectRunflat(e.target.checked))
  }

  return (
    <section className={styles.sections}>
      <form onSubmit={handleSubmit}>
        <div className={styles.caracteristicas_wrapper}>
          <div className={styles.title}>
            <FormattedMessage id="preferences.neumatico_interesa" />
          </div>

          {/* Tipo */}
          {process.env.GATSBY_WEB !== 'elpaso' && (
            <div className={styles.caracteristica}>
              <div className={styles.caracteristica_title}>
                <FormattedMessage id="preferences.tipo_neumatico" />
              </div>
              <div className={styles.caracteristica_content}>
                <RadioButtonYellow
                  onChange={handleChangeEstacion}
                  checked={selectorState.temporada.value === 'verano'}
                  label1={
                    <div className={styles.label_title}>
                      <FormattedMessage id="ficha-marca.verano_todo_ano" />
                    </div>
                  }
                  label2={
                    <div className={styles.label_subtitle}>
                      <FormattedMessage id="ficha-marca.verano_description" />
                    </div>
                  }
                  name="temporada"
                  value="verano"
                />
                {process.env.GATSBY_WEB !== 'rodimotor' && (
                  <RadioButtonYellow
                    onChange={handleChangeEstacion}
                    checked={selectorState.temporada.value === 'invierno'}
                    label1={
                      <div className={styles.label_title}>
                        <FormattedMessage id="ficha-marca.invierno" />
                      </div>
                    }
                    label2={
                      <div className={styles.label_subtitle}>
                        <FormattedMessage id="ficha-marca.invierno_description" />
                      </div>
                    }
                    name="temporada"
                    value="invierno"
                  />
                )}
                <RadioButtonYellow
                  onChange={handleChangeEstacion}
                  checked={selectorState.temporada.value === 'allseason'}
                  label1={
                    <div className={styles.label_title}>
                      <FormattedMessage id="ficha-marca.todo-el-año" />
                    </div>
                  }
                  label2={
                    <div className={styles.label_subtitle}>
                      <FormattedMessage id="ficha-marca.todo-el-año_description" />
                    </div>
                  }
                  name="temporada"
                  value="allseason"
                />
              </div>
            </div>
          )}

          {/* Opciones */}
          <div className={styles.caracteristica}>
            <div className={styles.caracteristica_title}>
              <FormattedMessage id="preferences.opciones" />
            </div>
            <div className={styles.caracteristica_runflat_content}>
              <CheckBoxYellow
                name="runflat"
                onChange={handleChangeRunflat}
                checked={selectorState.runflat.value}
                disableCheckBox={!selectorState.runflat.selectable}
                label1={
                  <div
                    className={
                      !selectorState.runflat.selectable
                        ? `${styles.label_title} ${styles.label_disabled}`
                        : styles.label_title
                    }>
                    <FormattedMessage id="preferences.runflat" />
                    <InfoIcon
                      title={t('preferences.neumaticos_antipinchazos')}
                      text={t(
                        'preferences.neumaticos_antipinchazos_description'
                      )}
                    />
                  </div>
                }
                label2={
                  <div
                    className={
                      !selectorState.runflat.selectable
                        ? `${styles.label_subtitle} ${styles.label_disabled}`
                        : styles.label_subtitle
                    }>
                    <FormattedMessage id="preferences.runflat_description" />
                  </div>
                }
              />
            </div>
          </div>

          {/* Marca */}
          <div className={styles.caracteristica}>
            <div className={styles.caracteristica_title}>
              <FormattedMessage id="selector-desktop.marca" />
            </div>
            <div className={styles.caracteristica_content}>
              <RadioButtonYellow
                onChange={onHandleRadioButtonChange}
                label1={
                  <div className={styles.label_title}>
                    <FormattedMessage id="ficha-marca.todas_marcas" />
                  </div>
                }
                checked={!showMarcasList}
                name="marca"
                value="marcas-all"
              />
              <RadioButtonYellow
                onChange={onHandleRadioButtonChange}
                checked={showMarcasList}
                label1={
                  <div className={styles.label_title}>
                    <FormattedMessage id="preferences.select" />
                  </div>
                }
                name="marca"
                value="marcas-list"
              />
              {showMarcasList && (
                <MarcasList
                  selectedMarca={selectorState.marca}
                  onMarcaSelect={onMarcaSelect}
                  marcasList={marcas}
                />
              )}
            </div>
          </div>
        </div>

        <SelectorFooterDesktop
          onGoBack={() => {
            navigate(selectorState.origen)
          }}
          searchButton
        />
      </form>
    </section>
  )
}

export default withMarcas(SelectorCaracteristicas)
