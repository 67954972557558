import React, { useEffect, useContext } from 'react'
import FunnelSideBar from '../../../components/funnelSideBar/FunnelSideBar'
import { t } from '../../../../common/i18n'
import { LayoutSelectorDesktop } from '../../layouts/layoutSelector/LayoutSelector'
import AppContext from '../../../context/context'
import MainFormContainer from '../../layouts/mainFormContainer/MainFormContainer'
import SelectorContainer from '../Entrada/SelectorContainer'
import SelectorMedidas from '../Entrada/SelectorMedidas'
import { reset } from '../../../context/actions/selectorActions'
import route from '../../../utils/route'

interface Props {
  path: string
}
const Medidas = (_props: Props) => {
  const { dispatch, selectorState } = useContext(AppContext)
  useEffect(() => {
    if (!selectorState.ancho.options.length && !selectorState.ancho.loading) {
      dispatch(
        reset({
          keepValues: false,
          marca: null,
          origen: route('index'),
          temporada: {
            selectable: process.env.GATSBY_WEB === 'rodi',
            value: 'verano',
          },
          tipoVehiculo: null,
        })
      )
    }
  }, [])
  return (
    <LayoutSelectorDesktop
      hideSelectorSteps={false}
      stepActive={1}
      title={t('medidas.title')}
      subTitle={t('medidas.subTitle')}>
      <MainFormContainer>
        <SelectorContainer showMarcas={false}>
          {({ ...props }) => <SelectorMedidas {...props} />}
        </SelectorContainer>
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorDesktop>
  )
}

export default Medidas
