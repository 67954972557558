import React from 'react'
import { NeumaticoResult } from '../../api/search'
import Next from '../../assets/images/Talleres/next.svg'
import { SelectorState } from '../../context/reducers/selectorReducer'
import { intl, t, textWithLocale } from '../../i18n'
import Button from '../buttons/Button'
import EstacionText from './EstacionText'
import styles from './index.module.scss'
import { isPromocionForProductos } from '../../context/selectors/cestaSelectors'
import { getDefaultNeumaticoUrl } from '../../utils/getDefaultNeumaticoImageUrl'
import { PromotionBanner } from '../promotionBanner/PromotionBanner'
import EcoLabels from '../EcoLabels/EcoLabels'

interface PriceProps {
  neumatico: NeumaticoResult
}
const Prices = ({ neumatico }: PriceProps) => {
  let crossedPrice
  if (
    (neumatico.promo && neumatico.promo !== '0.000') ||
    (neumatico.promocionCesta &&
      neumatico.promocionCesta.importePromocion &&
      isPromocionForProductos(neumatico.promocionCesta))
  ) {
    crossedPrice = (
      <div className={styles.priceSinPromo}>
        <del>
          {intl.formatNumber(neumatico.precioSinPromo, {
            style: 'currency',
            currency: 'EUR',
          })}
        </del>
      </div>
    )
  }
  return (
    <div className={styles.prices}>
      {crossedPrice}
      <div className={styles.price}>
        {neumatico.promocionCesta &&
        isPromocionForProductos(neumatico.promocionCesta)
          ? intl.formatNumber(
              neumatico.precio - neumatico.promocionCesta.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.precio, {
              style: 'currency',
              currency: 'EUR',
            })}
      </div>
      {/* <Stars starsFilled={neumatico.puntuacion}/> */}
      <div className={styles.ecotasa}>
        {neumatico.promocionCesta &&
        isPromocionForProductos(neumatico.promocionCesta)
          ? intl.formatNumber(
              neumatico.precioConEcotasa -
                neumatico.promocionCesta.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.precioConEcotasa, {
              style: 'currency',
              currency: 'EUR',
            })}{' '}
        {t('resultados_neumaticos.con_ecotasa')}
      </div>
    </div>
  )
}

const CardMobile = ({
  neumatico,
  onSelectNeumatico,
  selectedValues,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Logo = require(`../../assets/images/logo_marcas/${neumatico.logo}.svg`)
  return (
    <div className={styles.neumatico_results_card_mobile}>
      <div className={styles.content_top}>
        <div className={styles.neumatico}>
          <div className={styles.logoMarcaMobile}>
            <Logo className={styles.marca} />
          </div>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${
                neumatico.urlImagen || getDefaultNeumaticoUrl()
              })`,
            }}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.marca}>
            {neumatico.marca} {neumatico.modelo}
          </div>
          <div className={styles.characteristics}>
            {neumatico[textWithLocale('descripcion')] ||
              `${neumatico.ancho}/${neumatico.serie} R${neumatico.llanta} ${
                neumatico.ic
              }${neumatico.cv}${
                neumatico.marcaje ? ` - ${neumatico.marcaje}` : ''
              }`}
          </div>
          <EstacionText
            invierno={neumatico.invierno}
            runflat={neumatico.runflat}
            verano={neumatico.verano}
            allseason={neumatico.allseason}
          />
        </div>
        <Prices neumatico={neumatico} />
      </div>
      {neumatico.promocion && (
        <PromotionBanner promocion={neumatico.promocion} />
      )}
      <div className={styles.content_bottom}>
        <div className={styles.tags}>
          <EcoLabels
            consumo={neumatico.consumo}
            lluvia={neumatico.lluvia}
            ruido={neumatico.nivelruido}
            decibelios={neumatico.decibelios}
            eprel={neumatico.eprel}
            smallMobile={true}
          />
        </div>
        <Button
          buttonClass="rodi_button_mobile"
          onClick={() => onSelectNeumatico(neumatico)}>
          {t('resultados_neumaticos.elegir')}
        </Button>
      </div>
      {neumatico.equivalente === '1' ? (
        <p className={styles.equivalente_message}>
          {t('resultados_neumaticos.medidas_compatibles', {
            ancho: selectedValues.ancho.value,
            serie: selectedValues.serie.value,
            llanta: selectedValues.llanta.value,
            ic: selectedValues.carga.value ? selectedValues.carga.value : '',
            cv: selectedValues.velocidad.value
              ? selectedValues.velocidad.value
              : '',
          })}
        </p>
      ) : null}
    </div>
  )
}

const CardDesktop = ({
  neumatico,
  onSelectNeumatico,
  selectedValues,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Logo = require(`../../assets/images/logo_marcas/${neumatico.logo}.svg`)
  return (
    <div className={styles.neumatico_results_card}>
      <div className={styles.images}>
        <div className={styles.logoMarca}>
          <Logo className={styles.marca} />
        </div>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${
              neumatico.urlImagen || getDefaultNeumaticoUrl()
            })`,
          }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.marca}>
          {neumatico.marca} {neumatico.modelo}
        </div>
        <div className={styles.description}>
          {neumatico[textWithLocale('descripcion')] ||
            `${neumatico.ancho}/${neumatico.serie} R${neumatico.llanta} ${
              neumatico.ic
            }${neumatico.cv}${
              neumatico.marcaje ? ` - ${neumatico.marcaje}` : ''
            }`}
        </div>
        <EstacionText
          invierno={neumatico.invierno}
          runflat={neumatico.runflat}
          verano={neumatico.verano}
          allseason={neumatico.allseason}
        />

        {neumatico.promocion && (
          <PromotionBanner promocion={neumatico.promocion} />
        )}
        <div className={styles.tags}>
          <EcoLabels
            consumo={neumatico.consumo}
            lluvia={neumatico.lluvia}
            ruido={neumatico.nivelruido}
            decibelios={neumatico.decibelios}
            eprel={neumatico.eprel}
          />
        </div>
        {neumatico.equivalente === '1' ? (
          <p className={styles.equivalente_message}>
            {t('resultados_neumaticos.medidas_compatibles', {
              ancho: selectedValues.ancho.value,
              serie: selectedValues.serie.value,
              llanta: selectedValues.llanta.value,
              ic: selectedValues.carga.value ? selectedValues.carga.value : '',
              cv: selectedValues.velocidad.value
                ? selectedValues.velocidad.value
                : '',
            })}
          </p>
        ) : null}
      </div>
      <div className={styles.actions}>
        <Prices neumatico={neumatico} />
        <Button
          buttonClass="rodi_button"
          onClick={() => onSelectNeumatico(neumatico)}>
          {t('resultados_neumaticos.elegir')}
          <Next className={styles.icon_next} />
        </Button>
      </div>
    </div>
  )
}

interface Props {
  neumatico: NeumaticoResult
  onSelectNeumatico: (neumatico: NeumaticoResult) => void
  selectedValues: Partial<SelectorState>
}
export default function NeumaticoResultsCard({ ...props }: Props) {
  return (
    <React.Fragment>
      <CardMobile {...props} />
      <CardDesktop {...props} />
    </React.Fragment>
  )
}
