import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { reset, selectMedida } from '../../../context/actions/selectorActions'
import { t } from '../../../../common/i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import NeumaticoMobile from '../../NeumaticoMobile/NeumaticoMobile'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'
import LoadingOptions from './LoadingOptions'

type Props = FunnelScreenProps
const FormAncho = ({
  dispatch,
  nextStep,
  selectorState,
  totalSteps,
}: Props) => {
  const [showMore, setShowMore] = useState(false)

  const onHandleClick = (value: number) => {
    dispatch(selectMedida({ name: 'ancho', value }))
    nextStep()
  }

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  if (
    !selectorState.ancho.loading &&
    selectorState.ancho.options.length === 0
  ) {
    dispatch(
      reset({
        keepValues: false,
        origen: selectorState.origen,
        tipoVehiculo: selectorState.tipoVehiculo,
        temporada: selectorState.temporada,
        marca: selectorState.marca,
      })
    )
    return null
  }

  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={0}
      totalSteps={totalSteps}
      showSelectorFooter>
      <div className={styles.ancho_wrapper}>
        <NeumaticoMobile selectorState={selectorState} actual="ancho" />
        <div className={styles.title}>
          <FormattedMessage id="selector-mobile.ancho" />
        </div>
        {selectorState.ancho.loading ? (
          <LoadingOptions />
        ) : (
          <form className={styles.options_container}>
            <div className={styles.subtitle}>
              <FormattedMessage id="selector-mobile.medidas_usuales" />
            </div>
            <ul className={styles.option_list_container}>
              {selectorState.ancho.options &&
                selectorState.ancho.options.slice(0, 6).map((option, index) => (
                  <li
                    className={styles.option_list_item}
                    onClick={() => onHandleClick(option.value)}
                    key={index}>
                    {option.value}
                  </li>
                ))}
            </ul>

            {showMore ? (
              <React.Fragment>
                <ul className={styles.option_list_container}>
                  {selectorState.ancho.options &&
                    selectorState.ancho.options
                      .slice(6)
                      .map((option, index) => (
                        <li
                          className={styles.option_list_item}
                          onClick={() => onHandleClick(option.value)}
                          key={index}>
                          {option.value}
                        </li>
                      ))}
                </ul>
                <div onClick={toggleShowMore} className={styles.mostrar_menos}>
                  <FormattedMessage id="selector-mobile.menos_medidas" />
                </div>
              </React.Fragment>
            ) : (
              <div onClick={toggleShowMore} className={styles.mas_medidas}>
                <FontAwesomeIcon
                  className={styles.mas_medidas_icon}
                  icon={faPlusCircle}
                />
                <FormattedMessage id="selector-mobile.mas_medidas" />
              </div>
            )}
          </form>
        )}
      </div>
    </LayoutSelectorMobile>
  )
}

export default FormAncho
