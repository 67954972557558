import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/neumatico-selector.png'
import React from 'react'
import {
  MedidaName,
  SelectorState,
} from '../../context/reducers/selectorReducer'
import styles from './neumaticoMobile.module.scss'

interface ValorProps {
  actual: MedidaName
  nombre: MedidaName
  state: SelectorState
  prefix?: string
}
const Valor = ({ actual, nombre, state, prefix = '' }: ValorProps) => (
  <span
    className={`${styles[nombre]} ${
      state.pristine || state[nombre].value === null ? styles.default : ''
    } ${actual === nombre ? styles.parpadeo : ''}`}>
    {prefix}
    {state[nombre].value || state[nombre].default || ''}
  </span>
)

interface Props {
  selectorState: SelectorState
  actual: MedidaName
}
const NeumaticoMobile = ({ selectorState, actual }: Props) => {
  return (
    <div className={styles.neumatico}>
      <div className={styles.neumatico_container}>
        <img
          src={Neumatico}
          alt="Neumático genérico"
          width={450}
          height={133}
          className={styles.neumatico}
        />

        <Valor actual={actual} nombre="ancho" state={selectorState} />
        <span className={styles.line}> / </span>
        <Valor actual={actual} nombre="serie" state={selectorState} />
        <Valor
          actual={actual}
          nombre="llanta"
          state={selectorState}
          prefix="R"
        />
        <Valor actual={actual} nombre="carga" state={selectorState} />
        <Valor
          actual={actual}
          nombre="velocidad"
          state={selectorState}
          prefix=""
        />
      </div>
    </div>
  )
}

export default NeumaticoMobile
