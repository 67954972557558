import React from 'react'
import { t } from '../../../../common/i18n'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import { LayoutSelectorDesktop } from '../../layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../layouts/mainFormContainer/MainFormContainer'
import LeadFormContainer from '../Common/LeadFormContainer'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import { FunnelScreenProps } from '../SelectorSteps'

interface Props extends FunnelScreenProps {}
const FormTusDatos = ({
  dispatch,
  nextStep,
  previousStep,
  selectorState,
}: Props) => {
  return (
    <LayoutSelectorDesktop
      stepActive={3}
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('preferences.tus_preferencias')}>
      <MainFormContainer>
        <LeadFormContainer
          dispatch={dispatch}
          selectorState={selectorState}
          previousStep={previousStep}
          nextStep={nextStep}
          footer={props => (
            <SelectorFooterDesktop
              onGoBack={previousStep}
              compareButton
              {...props}
            />
          )}
        />
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorDesktop>
  )
}

export default FormTusDatos
