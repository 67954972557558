import React from 'react'
import FormTusDatosDesktop from '../Desktop/FormTusDatos'
import FormTusDatosMobile from '../Mobile/FormTusDatos'
import { FunnelScreenProps } from '../SelectorSteps'

interface Props extends FunnelScreenProps {
  device: 'mobile' | 'desktop'
}
const TusDatos = ({ device, ...props }: Props) => {
  return device === 'mobile' ? (
    <FormTusDatosMobile {...props} />
  ) : (
    <FormTusDatosDesktop {...props} />
  )
}
export default TusDatos
