import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NeumaticoResult } from '../../../../api/search'
import IconLazo from '../../../../../common/assets/icon_lazo.svg'
import { SelectorState } from '../../../../context/reducers/selectorReducer'
import { t } from '../../../../../common/i18n'
import { CategoriaNeumatico } from '../../../../types/Neumatico'
import NeumaticoResultsCard from '../../../neumaticoResultsCard/NeumaticoResultsCard'
import SpinnerWithText from '../../../spinnerWithText/SpinnerWithText'
import { NavisionState } from '../SearchResults'
import styles from '../searchResults.module.scss'

interface Props {
  searchState: NavisionState
  onSelectNeumatico: (neumatico: NeumaticoResult) => void
  selectedValues: Partial<SelectorState>
}

interface Categoria {
  categoria: CategoriaNeumatico
  title: string
  subtitle: string
}

const SECTIONS = [
  {
    categoria: 'TU_SELECCION',
    title: 'resultados_neumaticos.tu_seleccion',
    subtitle: 'resultados_neumaticos.tu_seleccion_subtitle',
  },
  {
    categoria: 'PRIORITY',
    title: 'resultados_neumaticos.en_promocion',
    subtitle: 'resultados_neumaticos.tu_seleccion_subtitle',
  },
  {
    categoria: 'PREMIUM',
    title: 'resultados_neumaticos.lo_mejor',
    subtitle: 'resultados_neumaticos.neumaticos_premium',
  },
  {
    categoria: 'QUALITY',
    title: 'resultados_neumaticos.calidad_precio',
    subtitle: 'resultados_neumaticos.neumaticos_quality',
  },
  {
    categoria: 'BUDGET',
    title: 'resultados_neumaticos.mas_ajustado',
    subtitle: 'resultados_neumaticos.neumaticos_BUDGET',
  },
] as Categoria[]

type SectionProps = Props & Categoria

const getMarca = (idMarca, results) => {
  const result = results.find(item => item.idMarca === idMarca)
  return result ? result.marca : null
}
const Section = ({
  searchState,
  categoria,
  title,
  subtitle,
  selectedValues,
  onSelectNeumatico,
}: SectionProps) => {
  const selectedMarca = getMarca(
    selectedValues.marca,
    searchState.results.neumaticos
  )

  const priorityMarca = searchState.results.priorityMarca
  let namePriorityMarca = null

  let filtered = searchState.results.neumaticos.filter(
    n =>
      n.categoria.toUpperCase() === categoria &&
      (priorityMarca
        ? n.idMarca !== priorityMarca
        : n.idMarca !== selectedValues.marca)
  )
  if (!selectedMarca && priorityMarca && categoria === 'PRIORITY') {
    filtered = searchState.results.neumaticos.filter(item => {
      if (item.idMarca === priorityMarca) {
        namePriorityMarca = item.marca
      }
      return item.idMarca === priorityMarca
    })
  }
  if (selectedMarca && categoria === 'TU_SELECCION') {
    filtered = searchState.results.neumaticos.filter(
      item => item.idMarca === selectedValues.marca
    )
  }
  if (filtered.length === 0) {
    return null
  }

  return (
    <section>
      <div className={styles.group_category}>
        <div className={styles.title}>
          <div className={styles.icon_container}>
            <IconLazo className={styles.icon_lazo} />
          </div>
          <div>
            <span className={styles.title_bold}>
              <FormattedMessage id={title} />
            </span>
            <span className={styles.tite_text}>
              {` ${t(subtitle, { marca: selectedMarca || namePriorityMarca })}`}
            </span>
          </div>
        </div>
        {filtered.map(neumatico => (
          <NeumaticoResultsCard
            selectedValues={selectedValues}
            onSelectNeumatico={onSelectNeumatico}
            key={neumatico.id}
            neumatico={neumatico}
          />
        ))}
      </div>
    </section>
  )
}

const WebResults = ({ searchState, selectedValues, ...props }: Props) =>
  searchState.loading ? (
    <SpinnerWithText
      text={t('selector.buscando_neumaticos')}
      className={styles.loading_container}
    />
  ) : (
    <React.Fragment>
      {SECTIONS.map(section => (
        <Section
          selectedValues={selectedValues}
          key={section.categoria}
          {...props}
          searchState={searchState}
          {...section}
        />
      ))}
    </React.Fragment>
  )

export default WebResults
