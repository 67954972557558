import React, { useCallback, useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Pagination from 'react-js-pagination'
import {
  OrderEntry,
  ORDER_OPTIONS,
  search,
} from '../../../../context/actions/searchActions'
import AppContext from '../../../../context/context'
import { SearchState } from '../../../../context/reducers/searchReducer'
import { t } from '../../../../../common/i18n'
import Size from '../../../../utils/media-queries-setup'
import DropDown from '../../../dropDown/DropDown'
import NeumaticoResultsCard from '../../../neumaticoResultsCard/NeumaticoResultsCard'
import NoResultsMessage from '../../../noResultsMessage/NoResultsMessage'
import SpinnerWithText from '../../../spinnerWithText/SpinnerWithText'
import styles from '../searchResults.module.scss'
import DBFilters from './DBFilters'
import { NeumaticoResult } from '../../../../api/search'

const PAGE_SIZE = 10

interface Props {
  searchState: SearchState
  onSelectNeumatico: (neumatico: NeumaticoResult) => void
}
const DBResults = ({ searchState, onSelectNeumatico }: Props) => {
  const { dispatch, selectorState } = useContext(AppContext)
  const [order, setOrder] = useState(ORDER_OPTIONS[0])
  const neumaticos = searchState.results.neumaticos
  const getNeumaticos = useCallback(
    (pageNumber: number, order: OrderEntry) => {
      dispatch(
        search({
          applyMarcas: true,
          pagination: {
            pageSize: PAGE_SIZE,
            page: pageNumber,
          },
          order: order.field,
          direction: order.direction,
        })
      )
    },
    [selectorState, dispatch]
  )

  const handlePageChange = (page: number) => {
    getNeumaticos(page - 1, order)
  }

  const changeOrder = event => {
    event.preventDefault()
    const value = event.target.value
    const order = ORDER_OPTIONS.find(o => o.value === value)
    setOrder(order)
    getNeumaticos(0, order)
  }

  const update = useCallback(() => {
    getNeumaticos(0, order)
  }, [getNeumaticos, order])

  return (
    <section className={styles.tyresSearcDBFilter_wrapper}>
      <DBFilters update={update} />

      <div className={styles.order_by_wrapper}>
        <div>
          <span className={styles.mostrando_text}>
            <FormattedMessage id="resultados_neumaticos.mostrando" />
          </span>
          {searchState.results.neumaticos.length} de {searchState.results.total}{' '}
          <FormattedMessage id="menuHeader.neumaticos" />
        </div>
        <div className={styles.order}>
          <span className={styles.order_text}>
            <FormattedMessage id="resultados_neumaticos.ordenar_por" />:{' '}
          </span>
          <DropDown
            name="orderBy"
            onChange={changeOrder}
            options={ORDER_OPTIONS}
          />
        </div>
      </div>

      {searchState.loading ? (
        <SpinnerWithText
          text={t('selector.buscando_neumaticos')}
          className={styles.loading_container}
        />
      ) : (
        <React.Fragment>
          <div className={styles.neumaticos_cards_wrapper}>
            {neumaticos &&
              neumaticos.map((neumatico, index) => (
                <NeumaticoResultsCard
                  onSelectNeumatico={onSelectNeumatico}
                  key={index}
                  neumatico={neumatico}
                  selectedValues={selectorState}
                />
              ))}
          </div>
          {neumaticos && !neumaticos.length && <NoResultsMessage />}
        </React.Fragment>
      )}

      {neumaticos && neumaticos.length > 0 ? (
        <Size.Matcher
          desktop={
            <Pagination
              activePage={searchState.query.pagination.page + 1}
              itemsCountPerPage={PAGE_SIZE}
              totalItemsCount={searchState.results.total}
              itemClass="item"
              hideDisabled
              hideFirstLastPages
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
              nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
            />
          }
          mobile={
            <MobilePagination
              handlePageChange={handlePageChange}
              currentPage={searchState.query.pagination.page + 1}
              totalPages={searchState.results.pages}
              totalItems={searchState.results.total}
            />
          }
        />
      ) : null}
    </section>
  )
}

interface MobilePaginationProps {
  handlePageChange: (page: number) => void
  currentPage: number
  totalPages: number
  totalItems: number
}
const MobilePagination = ({
  handlePageChange,
  currentPage,
  totalPages,
  totalItems,
}: MobilePaginationProps) => (
  <div className={styles.pagination_mobile}>
    <span>
      {t('resultados_neumaticos.pagina')} {currentPage} de {totalPages}
    </span>
    <Pagination
      activePage={currentPage}
      itemsCountPerPage={PAGE_SIZE}
      totalItemsCount={totalItems}
      itemClass="item"
      hideDisabled
      hideFirstLastPages
      pageRangeDisplayed={1}
      onChange={handlePageChange}
      prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
      nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
    />
  </div>
)

export default DBResults
