import React from 'react'
import { selectEstacion } from '../../../context/actions/selectorActions'
import { t } from '../../../../common/i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'

const options = [
  {
    name: 'verano',
    key: 'verano',
    label: t('ficha-marca.verano_todo_ano'),
    description: t('ficha-marca.verano_description'),
    checked: 0,
  },
  {
    name: 'invierno',
    key: 'invierno',
    label: t('ficha-marca.invierno'),
    description: t('ficha-marca.invierno_description'),
    checked: 0,
  },
  {
    name: 'allseason',
    key: 'allseason',
    label: t('ficha-marca.todo-el-año'),
    description: t('ficha-marca.todo-el-año_description'),
    checked: 0,
  },
]

type Props = FunnelScreenProps
const FormEstacion = ({
  dispatch,
  nextStep,
  selectorState,
  totalSteps,
}: Props) => {
  const handleCheckBoxChange = (name) => {
    dispatch(selectEstacion(name))
    nextStep()
  }

  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={5}
      totalSteps={totalSteps}
      subTitle={t('preferences.tipo_neumatico')}
      showSelectorFooter>
      <form className={styles.options_container}>
        <ul className={styles.option_list_container}>
          {options.map((option, index) => (
            <li
              onClick={() => handleCheckBoxChange(option.name)}
              className={styles.option_list_item_estacion}
              key={index}>
              <div className={styles.estacion_title}>{option.label}</div>
              <div className={styles.estacion_subtitle}>
                {option.description}
              </div>
            </li>
          ))}
        </ul>
      </form>
    </LayoutSelectorMobile>
  )
}
export default FormEstacion
