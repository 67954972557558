import React from 'react'
import { FormattedMessage } from 'react-intl'
import { t } from '../../../../common/i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import LeadFormContainer from '../Common/LeadFormContainer'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'

type Props = FunnelScreenProps
const FormTusDatos = ({
  dispatch,
  nextStep,
  previousStep,
  selectorState,
  totalSteps,
}: Props) => {
  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={8}
      totalSteps={totalSteps}
      showCopyrightFooter>
      <div className={styles.title}>
        <FormattedMessage id="steps.tus_datos" />
      </div>
      <LeadFormContainer
        dispatch={dispatch}
        selectorState={selectorState}
        previousStep={previousStep}
        nextStep={nextStep}
        footer={(props) => <SelectorFooterDesktop compareButton {...props} />}
      />
    </LayoutSelectorMobile>
  )
}

export default FormTusDatos
