import {
  faChevronDown,
  faChevronUp,
  faPlusCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  changeFiltroMarcas,
  selectEstacion,
  selectRunflat,
} from '../../../../context/actions/selectorActions'
import { IAppState } from '../../../../context/context'
import {
  SelectorState,
  Temporada,
} from '../../../../context/reducers/selectorReducer'
import { selectSelector } from '../../../../context/selectors/selectorSelectors'
import CheckBoxYellow from '../../../checkboxYellow/CheckBoxYellow'
import withMarcas, { InjectedMarcas } from '../../../withMarcas'
import styles from './DBFilters.module.scss'
import RadioButtonYellow from '../../../radioButtonYellow/RadioButtonYellow'
import { t } from '../../../../../common/i18n'

interface SectionProps {
  index: number
  current: number
  title: string
  toggle: (index: number) => void
  children: any
  className?: string
}
const Section = ({
  index,
  current,
  title,
  toggle,
  children,
  className,
}: SectionProps) => {
  const open = index === current
  const classes = [styles.section]
  if (open) {
    classes.push(styles.open)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(' ')}>
      <div
        className={styles.section_title}
        onClick={() => {
          toggle(open ? 0 : index)
        }}>
        {title}
        {open ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>

      <div className={styles.section_content}>{children}</div>
    </div>
  )
}

interface TemporadaRadioProps {
  selectorState: SelectorState
  temporada: Temporada
  handleChangeEstacion: (estacion: Temporada) => void
  label: string
}
const TemporadaRadio = ({
  selectorState,
  temporada,
  handleChangeEstacion,
  label,
}: TemporadaRadioProps) =>
  selectorState.filterables.temporadas.includes(temporada) ? (
    <RadioButtonYellow
      value={temporada}
      name={temporada}
      onChange={() => handleChangeEstacion(temporada)}
      checked={selectorState.temporada.value === temporada}
      variant="small"
      label1={<div className={styles.label}>{label}</div>}
    />
  ) : null

interface FilterProps extends InjectedMarcas {
  filterOpen: number
  handleOnClickFilter: (n_filter: number) => void
  selectorState: SelectorState
  showMore: boolean
  toggleShowMore: () => void
  handleChangeMarcas: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeRunflat: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeEstacion: (name: string) => void
}

const Contents = ({
  filterOpen,
  handleOnClickFilter,
  selectorState,
  handleChangeMarcas,
  showMore,
  toggleShowMore,
  marcas,
  handleChangeEstacion,
  handleChangeRunflat,
}: FilterProps) => {
  return (
    <div className={styles.container}>
      <Section
        index={1}
        current={filterOpen}
        title={t('ficha-marca.marcas')}
        toggle={handleOnClickFilter}
        className={styles.marcas_container}>
        <ul className={styles.marcas}>
          {marcas.slice(0, 6).map((marca) => (
            <li key={marca.value}>
              <CheckBoxYellow
                name={marca.label}
                value={marca.value}
                onChange={handleChangeMarcas}
                checked={selectorState.filtroMarcas.includes(marca.value)}
                size="small"
                label1={<div className={styles.label}>{marca.label}</div>}
              />
            </li>
          ))}

          {showMore &&
            marcas.slice(6).map((marca) => (
              <li key={marca.value}>
                <CheckBoxYellow
                  name={marca.label}
                  value={marca.value}
                  onChange={handleChangeMarcas}
                  checked={selectorState.filtroMarcas.includes(marca.value)}
                  size="small"
                  label1={<div className={styles.label}>{marca.label}</div>}
                />
              </li>
            ))}
        </ul>

        {showMore ? (
          <div onClick={toggleShowMore} className={styles.toggle_medidas}>
            {t('selector-mobile.menos_marcas')}
          </div>
        ) : (
          marcas.length > 6 && (
            <div onClick={toggleShowMore} className={styles.toggle_medidas}>
              <FontAwesomeIcon
                className={styles.toggle_medidas_icon}
                icon={faPlusCircle}
              />
              {t('selector-mobile.mas_marcas')}
            </div>
          )
        )}
      </Section>

      {/* Estacion */}
      {process.env.GATSBY_WEB === 'rodi' && (
        <Section
          index={2}
          current={filterOpen}
          title={t('resultados_neumaticos.estacion')}
          toggle={handleOnClickFilter}>
          <TemporadaRadio
            label={t('selector-mobile.verano')}
            temporada="verano"
            selectorState={selectorState}
            handleChangeEstacion={handleChangeEstacion}
          />
          <TemporadaRadio
            label={t('selector-mobile.invierno')}
            temporada="invierno"
            selectorState={selectorState}
            handleChangeEstacion={handleChangeEstacion}
          />
          <TemporadaRadio
            label={t('selector-mobile.todo-el-año')}
            temporada="allseason"
            selectorState={selectorState}
            handleChangeEstacion={handleChangeEstacion}
          />
        </Section>
      )}
      {/* Opciones */}
      <Section
        index={3}
        current={filterOpen}
        title={t('resultados_neumaticos.opciones')}
        toggle={handleOnClickFilter}>
        <CheckBoxYellow
          key="runflat"
          name="runflat"
          onChange={handleChangeRunflat}
          checked={
            selectorState.filterables.runflat.length < 2
              ? !!selectorState.filterables.runflat[0]
              : !!selectorState.runflat.value
          }
          disableCheckBox={selectorState.filterables.runflat.length < 2}
          size="small"
          label1={
            <div className={styles.label}>{t('preferences.runflat')}</div>
          }
        />
      </Section>
    </div>
  )
}
interface Props extends InjectedMarcas {
  update: () => void
  dispatch: Dispatch
  selectorState: SelectorState
}
const DBFilters = ({ update, dispatch, selectorState, marcas }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [filterOpen, setFilterOpen] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const filteredMarcas = useMemo(() => {
    return marcas.filter(
      (m) => selectorState.filterables.marcas.includes(m.value) && m.coche
    )
  }, [marcas, selectorState.filterables.marcas])

  const handleChangeEstacion = (estacion: Temporada) => {
    dispatch(selectEstacion(estacion))
    update()
  }

  const handleChangeRunflat = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(selectRunflat(event.target.checked))
    update()
  }

  const handleChangeMarcas = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    dispatch(changeFiltroMarcas(parseInt(value), checked))
    update()
  }

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleOnClick = () => {
    setExpanded(!expanded)
  }

  const handleOnClickFilter = (n_filter: number) => {
    setFilterOpen(n_filter)
  }

  return (
    <div className={styles.filtros}>
      <div className={styles.main_title} onClick={handleOnClick}>
        {t('resultados_neumaticos.filtrar_por')}
        {expanded ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>
      {expanded && (
        <Contents
          marcas={filteredMarcas}
          filterOpen={filterOpen}
          handleOnClickFilter={handleOnClickFilter}
          handleChangeMarcas={handleChangeMarcas}
          showMore={showMore}
          toggleShowMore={toggleShowMore}
          selectorState={selectorState}
          handleChangeEstacion={handleChangeEstacion}
          handleChangeRunflat={handleChangeRunflat}
        />
      )}
    </div>
  )
}

export default connect((state: IAppState) => {
  return {
    selectorState: selectSelector(state),
  }
})(withMarcas(DBFilters))
