import React, { useContext, useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { registerLead } from '../../../api/user'
import { search } from '../../../context/actions/searchActions'
import { loadLeadInfo } from '../../../context/actions/userActions'
import AppContext from '../../../context/context'
import { LeadInfo } from '../../../context/reducers/userReducer'
import { ValidateCP } from '../../../../specific/helpers/validation/validationCP'
import { t } from '../../../../common/i18n'
import { alertProp } from '../../../utils/swal'
import LoginBar from '../../LoginBar/LoginBar'
import RegisterContainer from '../../registerForm/RegisterContainer'
import { FunnelScreenProps } from '../SelectorSteps'
import { LeadForm, RegisterData } from './LeadForm'
import { validatePhone } from '../../../../specific/helpers/validation/validationPhone'

const RegisterDataFields = {
  email: yup
    .string()
    .email(t('validations.register.format', { label: t('formLabels.email') }))
    .required(
      t('validations.register.required', { label: t('formLabels.email') })
    ),
  phone: yup
    .string()
    .test('test-phone_number', '${message}', validatePhone)
    .required(
      t('validations.register.required', { label: t('formLabels.phone') })
    ),
  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      cp => ValidateCP(cp)
    )
    .required(
      t('validations.register.required', { label: t('formLabels.post_code') })
    ),
  checkboxPrivacy: yup
    .boolean()
    .oneOf([true], t('validations.register.accept_terms')),
}

const preRegisterSchema = yup.object().shape(RegisterDataFields)

interface Props extends FunnelScreenProps {
  footer: (props: {}) => JSX.Element
}
export default function LeadFormContainer({
  previousStep,
  nextStep,
  dispatch,
  footer,
  selectorState,
}: Props) {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
    setError,
    clearError,
    triggerValidation,
  } = useForm<RegisterData>({
    mode: 'onSubmit',
    validationSchema: preRegisterSchema,
  })
  const email = watch('email', '') as string
  const { searchState } = useContext(AppContext)
  const [isModalOpen, setOpenModal] = useState(false)
  const [showRegister, setShowRegister] = useState(false)

  useEffect(() => {
    dispatch(search())
  }, [])

  const onSubmit = async (data: LeadInfo) => {
    data.origen = selectorState.origen
    registerLead(data)
      .then(data => {
        if (data.errors) {
          data.errors.forEach(e => {
            setError(e.field, e.type, t(e.message))
          })
        } else {
          dispatch(loadLeadInfo(data))
          nextStep()
        }
      })
      .catch(e => {
        if (e.message === 'USER_EXISTS') {
          setOpenModal(true)
        } else {
          Swal.fire(
            alertProp({
              type: 'error',
              title: 'Oops...',
              text: t('pre_register.error'),
            })
          )
        }
      })
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <React.Fragment>
      {!showRegister ? (
        <React.Fragment>
          <LoginBar
            changeLoginbarType={() => setShowRegister(true)}
            loginBarType="register"
          />
          <LeadForm
            email={email}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isModalOpen={isModalOpen}
            onCloseModal={onCloseModal}
            neumaticosEncontrados={
              searchState.loading ? null : searchState.results.total
            }
            errors={errors}
            formRef={register}
            previousStep={previousStep}
            footer={footer}
            formState={formState}
            nextStep={nextStep}
            clearError={clearError}
            triggerValidation={triggerValidation}
          />
        </React.Fragment>
      ) : (
        <RegisterContainer
          neumaticosEncontrados={
            searchState.loading ? null : searchState.results.total
          }
          onLoginSuccess={() => nextStep()}
          onRegisterSuccess={() => nextStep()}
          showLoginBar="login"
          footer={footer}
          footerData={{
            count: !searchState.results.total
              ? '...'
              : searchState.results.total,
          }}
        />
      )}
    </React.Fragment>
  )
}
