import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  NavisionSearch,
  NeumaticoResult,
  searchNavisionNeumaticos,
} from '../../../api/search'
import * as CestaActions from '../../../context/actions/cestaActions'
import { search } from '../../../context/actions/searchActions'
import { reset } from '../../../context/actions/selectorActions'
import AppContext, { IAppState } from '../../../context/context'
import { SearchState } from '../../../context/reducers/searchReducer'
import {
  selectMedidas,
  selectSelector,
} from '../../../context/selectors/selectorSelectors'
import {
  selectEmail,
  selectPhone,
  selectUserInfo,
} from '../../../context/selectors/userSelectors'
import { t } from '../../../i18n'
import { reportResults } from '../../../../specific/helpers/gtmReporter'
import Size from '../../../utils/media-queries-setup'
import { DataSteps, Steps } from '../../cesta/Steps'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import StandardModal from '../../modal/standardModal/StandardModal'
import RegisterContainer from '../../registerForm/RegisterContainer'
import SelectorModal from '../Entrada/SelectorModal'
import Step, { FunnelScreenProps, StepDetails } from '../SelectorSteps'
import styles from './searchResults.module.scss'
import DBResults from './SearchResults/DBResults'
import WebResults from './SearchResults/WebResults'
import { addSearchToken } from '../../../context/actions/cestaActions'

const initialState = {
  loading: false as boolean,
  error: '' as string,
  results: {
    neumaticos: [] as NeumaticoResult[],
    priorityMarca: null as number | null,
  },
}
export type NavisionState = typeof initialState

type NavisionAction =
  | { type: 'LOADING' }
  | {
      type: 'LOADED'
      payload: {
        neumaticos: NeumaticoResult[]
        priorityMarca: number | null
      }
    }
  | { type: 'ERROR'; payload: string }

function navisionReducer(state: NavisionState, action: NavisionAction) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...initialState,
        loading: true,
      }
    case 'LOADED':
      return {
        ...initialState,
        loading: false,
        results: action.payload,
      }
    case 'ERROR':
      return {
        ...initialState,
        error: action.payload,
      }
  }
}

function stateToNavisionQuery(
  appState: IAppState,
  device: 'MOBILE' | 'TABLET' | 'DESKTOP'
): NavisionSearch {
  const selectorState = selectSelector(appState)
  const userInfo = selectUserInfo(appState)

  const permiso_comercial = userInfo.userData
    ? userInfo.userData.permiso_comercial
    : userInfo.userLead && userInfo.userLead.permiso_comercial

  const query = {
    tipovehiculo: selectorState.tipoVehiculo || 3,
    ancho: selectorState.ancho.value,
    serie: selectorState.serie.value,
    llanta: selectorState.llanta.value,
    ic: selectorState.carga.value,
    cv: selectorState.velocidad.value,
    runflat: selectorState.runflat.value,
    invierno: selectorState.temporada.value === 'invierno',
    allseason: selectorState.temporada.value === 'allseason',
    email: selectEmail(appState),
    phone: selectPhone(appState),
    zipcode: userInfo.postCode,
    origen: selectorState.origen,
    permiso_comercial: permiso_comercial || 0,
    device,
  } as NavisionSearch

  if (selectorState.marca) {
    query.marca = selectorState.marca
  }

  return query
}

type ContentProps = Props & {
  searchState: SearchState
  navisionState: NavisionState
  loading: boolean
  dispatch: (action: any) => void
  onSelectNeumatico: (neumatico: NeumaticoResult) => void
  showSelectorModal: boolean
  closeSelectorModal: () => void
  onSelectorModalSubmit: () => void
}
const SearchResultsContent = ({
  searchState,
  selectorState,
  navisionState,
  previousStep,
  nextStep,
  onSelectNeumatico,
  showSelectorModal,
  closeSelectorModal,
  onSelectorModalSubmit,
  page,
}: ContentProps) => {
  const zeroNavisionResults =
    !navisionState.loading &&
    (!navisionState.results.neumaticos ||
      !navisionState.results.neumaticos.length)
  const pageToShow = zeroNavisionResults ? 'todos' : page

  return (
    <div className={styles.search_results_wrapper}>
      <ul className={styles.tabs}>
        {/* Navision */}
        <li
          className={
            pageToShow !== 'todos'
              ? `${styles.tab} ${styles.is_active}`
              : `${styles.tab} ${styles.no_active}`
          }
          onClick={() => {
            if (pageToShow === 'todos') {
              navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop, {
                replace: true,
              })
            }
          }}>
          {!zeroNavisionResults && (
            <a className={styles.tab_text}>
              {`${t('resultados_neumaticos.recomendados')} ${
                navisionState.loading
                  ? '(...)'
                  : `(${navisionState.results.neumaticos.length})`
              }
              `}
            </a>
          )}
        </li>

        {/* DB */}
        {(searchState.results.total > 0 || pageToShow === 'todos') && (
          <li
            className={
              pageToShow === 'todos'
                ? `${styles.tab} ${styles.is_active}`
                : `${styles.tab} ${styles.no_active}`
            }
            onClick={() => {
              if (pageToShow === 'resultados') {
                nextStep()
              }
            }}>
            <a className={styles.tab_text}>
              {`${t('resultados_neumaticos.todos')} (${
                searchState.loading ? '...' : searchState.results.total
              })`}
            </a>
          </li>
        )}
      </ul>

      <div className={styles.results_container}>
        {pageToShow !== 'todos' && (
          <WebResults
            selectedValues={selectorState}
            onSelectNeumatico={onSelectNeumatico}
            searchState={navisionState}
          />
        )}
        {pageToShow === 'todos' && (
          <DBResults
            onSelectNeumatico={onSelectNeumatico}
            searchState={searchState}
          />
        )}
      </div>

      <div className={styles.resultsFooter}>
        <div className={styles.resultsFooter_left}>
          <span className={styles.option} onClick={() => previousStep(false)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={styles.go_back_icon}
            />
            {t('cesta.general.volver_atras')}
          </span>
          <span className={styles.option}>|</span>
          <span
            className={styles.option}
            onClick={() => {
              navigate(StepDetails[Step.INITIAL].routes.desktop)
            }}>
            {t('resultados_neumaticos.cambiar_preferencias')}
          </span>
        </div>
        {pageToShow !== 'todos' &&
          !searchState.loading &&
          searchState.results.total > 0 && (
            <div className={styles.show_all} onClick={nextStep}>
              {t('resultados_neumaticos.mostrar_todos', {
                count: searchState.results.total,
              })}
            </div>
          )}
      </div>
      <SelectorModal
        closeModal={closeSelectorModal}
        showModal={showSelectorModal}
        title={t('selector.modifica_busqueda')}
        onSubmit={onSelectorModalSubmit}
        showMarcas={false}
      />
    </div>
  )
}

interface Props extends FunnelScreenProps {
  appState: IAppState
  page: 'resultados' | 'registro' | 'todos'
}

const SearchResults = ({
  dispatch,
  appState,
  selectorState,
  previousStep,
  nextStep,
  page,
}: Props) => {
  const { searchState } = useContext(AppContext)
  const [navisionState, dispatchNavision] = useReducer(
    navisionReducer,
    initialState
  )
  const [isSelectorModalOpen, setSelectorModalOpen] = useState(false)

  const device = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const searchNavision = () => {
    dispatchNavision({ type: 'LOADING' })
    searchNavisionNeumaticos(stateToNavisionQuery(appState, device))
      .then((results) => {
        dispatchNavision({
          type: 'LOADED',
          payload: {
            neumaticos: results.neumaticos,
            priorityMarca: results.priorityMarca,
          },
        })
        dispatch(addSearchToken(results.token))
      })
      .catch((err) => {
        dispatchNavision({ type: 'ERROR', payload: err.message })
      })
  }

  const searchDatabase = () => {
    dispatch(search())
  }

  const refresh = () => {
    setSelectorModalOpen(false)
    searchNavision()
    searchDatabase()
    navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
  }

  const onSelectNeumatico = (neumatico: NeumaticoResult) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: neumatico.id,
          categoria: neumatico.categoria,
          marca: neumatico.marca,
          type: 'neumatico',
        },
        page === 'todos' ? 'database' : 'navision'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const subTitle = useMemo(
    () =>
      ` ${t('selector.para')} ${selectorState.ancho.value} / ${
        selectorState.serie.value
      } R${selectorState.llanta.value} ${selectorState.carga.value}${
        selectorState.velocidad.value
      }`,
    [selectorState]
  )

  useEffect(() => {
    searchNavision()
  }, [])

  useEffect(() => {
    if (
      page === 'todos' &&
      searchState.results.neumaticos &&
      searchState.results.neumaticos.length
    ) {
      reportResults({ page, state: searchState })
    }
    if (
      page === 'resultados' &&
      navisionState.results &&
      navisionState.results.neumaticos.length
    ) {
      reportResults({ page, state: navisionState })
    }
  }, [page, searchState.results.neumaticos, navisionState.results])
  const loading = page === 'todos' ? searchState.loading : navisionState.loading

  return (
    <LayoutSelector
      stepActive={4}
      goToBeginning={() => {
        navigate(StepDetails[Step.INITIAL].routes.mobile)
        dispatch(
          reset({
            keepValues: true,
            keepMedidas: selectMedidas(appState),
            marca: null,
            origen: selectorState.origen,
            temporada: {
              selectable: process.env.GATSBY_WEB === 'rodi',
              value: 'verano',
            },
            tipoVehiculo: null,
          })
        )
      }}
      showSelectorModal={() => setSelectorModalOpen(true)}
      lastStep={true}
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('steps.resultados')}
      subTitle2={subTitle}
      showCopyrightFooter>
      <div className={styles.router_div}>
        <SearchResultsContent
          loading={loading}
          searchState={searchState}
          selectorState={selectorState}
          navisionState={navisionState}
          dispatch={dispatch}
          previousStep={previousStep}
          nextStep={nextStep}
          appState={appState}
          onSelectNeumatico={onSelectNeumatico}
          onSelectorModalSubmit={refresh}
          page={page}
          closeSelectorModal={() => {
            setSelectorModalOpen(false)
          }}
          showSelectorModal={isSelectorModalOpen}
        />
        <StandardModal
          title={t('selector.completa_registro')}
          closeModal={previousStep}
          modalClass={styles.register_modal}
          showModal={page === 'registro'}>
          <RegisterContainer
            showLoginBar="register"
            onLoginSuccess={nextStep}
            onRegisterSuccess={nextStep}
          />
        </StandardModal>
      </div>
      <Size.Matcher desktop={<FunnelSideBar />} tablet={null} mobile={null} />
    </LayoutSelector>
  )
}

export default connect((state: IAppState) => ({ appState: state }))(
  SearchResults
)
