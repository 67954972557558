import React from 'react'
import styles from './marcasList.module.scss'
import { ListaMarcas } from '../../../context/reducers/selectorReducer'
import { sortBy } from 'lodash'
import { t } from '../../../i18n'

interface Props {
  marcasList: ListaMarcas
  onMarcaSelect: (marca: number) => void
  onSelectTodas: () => void
}

const MarcasListMobile = ({
  marcasList,
  onMarcaSelect,
  onSelectTodas,
}: Props) => {
  const marcas = sortBy(
    marcasList.filter((m) => {
      return m.coche
    }),
    ['orden']
  )
  return (
    <React.Fragment>
      <div onClick={onSelectTodas} className={styles.all_marcas_selection}>
        {t('ficha-marca.todas')}
      </div>
      <span className={styles.classification_title}>
        {t('ficha-marca.marcas_premium')}
      </span>
      <ul className={styles.option_list_container}>
        {marcas
          .filter((item) => item.clasificacion === 'PREMIUM')
          .map((item, index) => (
            <li
              className={styles.option_list_item}
              key={index}
              onClick={() => onMarcaSelect(item.value)}
              value={item.value}>
              {item.label}
            </li>
          ))}
      </ul>
      <span className={styles.classification_title}>
        {t('ficha-marca.marcas_quality')}
      </span>
      <ul className={styles.option_list_container}>
        {marcas
          .filter((item) => item.clasificacion === 'QUALITY')
          .map((item, index) => (
            <li
              className={styles.option_list_item}
              key={index}
              onClick={() => onMarcaSelect(item.value)}
              value={item.value}>
              {item.label}
            </li>
          ))}
      </ul>
      <span className={styles.classification_title}>
        {t('ficha-marca.marcas_BUDGET')}
      </span>
      <ul className={styles.option_list_container}>
        {marcas
          .filter((item) => item.clasificacion === 'BUDGET')
          .map((item, index) => (
            <li
              className={styles.option_list_item}
              key={index}
              onClick={() => onMarcaSelect(item.value)}
              value={item.value}>
              {item.label}
            </li>
          ))}
      </ul>
    </React.Fragment>
  )
}

export default MarcasListMobile
