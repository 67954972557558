import React, { useEffect } from 'react'
import {
  selectMarca,
  setMarcas,
} from '../../../context/actions/selectorActions'
import { t } from '../../../i18n'
import { LayoutSelectorMobile } from '../../layouts/layoutSelector/LayoutSelector'
import withMarcas, { InjectedMarcas } from '../../withMarcas'
import { FunnelScreenProps } from '../SelectorSteps'
import styles from './index.module.scss'
import MarcasListMobile from './MarcasList'

interface Props extends FunnelScreenProps, InjectedMarcas {}
const FormMarcas = ({ dispatch, nextStep, marcas, totalSteps }: Props) => {
  useEffect(() => {
    dispatch(setMarcas(marcas))
  }, [])

  const onMarcaSelect = (marca: number) => {
    dispatch(selectMarca(marca))
    nextStep()
  }

  const onSelectTodas = () => {
    dispatch(selectMarca(null))
    nextStep()
  }

  return (
    <LayoutSelectorMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={7}
      totalSteps={totalSteps}
      subTitle={t('ficha-marca.marcas')}
      showCopyrightFooter>
      <div className={styles.options_container}>
        <MarcasListMobile
          onSelectTodas={onSelectTodas}
          marcasList={marcas}
          onMarcaSelect={onMarcaSelect}
        />
      </div>
    </LayoutSelectorMobile>
  )
}

export default withMarcas(FormMarcas)
