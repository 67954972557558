import React from 'react'
import styles from './marcasList.module.scss'
import RadioButtonYellow from '../../radioButtonYellow/RadioButtonYellow'
import { ListaMarcas } from '../../../context/reducers/selectorReducer'
import { sortBy } from 'lodash'
import { t } from '../../../i18n'

interface Props {
  selectedMarca: null | number
  marcasList: ListaMarcas
  onMarcaSelect: (marca: number) => void
}
const MarcasList = ({ selectedMarca, marcasList, onMarcaSelect }: Props) => {
  const marcas = sortBy(
    marcasList.filter((m) => {
      return m.coche
    }),
    ['orden']
  )
  return (
    <div className={styles.marcas_list_wrapper}>
      <div className={styles.marcas_wrapper}>
        <div className={styles.title}>{t('ficha-marca.marcas_premium')}</div>
        {marcas
          .filter((item) => item.clasificacion === 'PREMIUM')
          .map((item, index) => (
            <div key={index}>
              <RadioButtonYellow
                checked={selectedMarca === item.value}
                onChange={onMarcaSelect}
                label1={<div className={styles.label_title}>{item.label}</div>}
                name="caracteristicas-radio"
                value={item.value}
              />
            </div>
          ))}
      </div>

      <div className={styles.marcas_wrapper}>
        <div className={styles.title}>{t('ficha-marca.marcas_quality')}</div>
        {marcas
          .filter((item) => item.clasificacion === 'QUALITY')
          .map((item, index) => (
            <div key={index}>
              <RadioButtonYellow
                checked={selectedMarca === item.value}
                onChange={onMarcaSelect}
                label1={<div className={styles.label_title}>{item.label}</div>}
                name="caracteristicas-radio"
                value={item.value}
              />
            </div>
          ))}
      </div>

      <div className={styles.marcas_wrapper}>
        <div className={styles.title}>{t('ficha-marca.marcas_BUDGET')}</div>
        {marcas
          .filter((item) => item.clasificacion === 'BUDGET')
          .map((item, index) => (
            <div key={index}>
              <RadioButtonYellow
                checked={selectedMarca === item.value}
                onChange={onMarcaSelect}
                label1={<div className={styles.label_title}>{item.label}</div>}
                name="caracteristicas-radio"
                value={item.value}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default MarcasList
