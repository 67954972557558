import React from 'react'
import { t } from '../../../i18n'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import { LayoutSelectorDesktop } from '../../layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../layouts/mainFormContainer/MainFormContainer'
import { FunnelScreenProps } from '../SelectorSteps'
import SelectorCaracteristicas from './SelectorCaracteristicas'

type Props = FunnelScreenProps
const FormPreferencias = ({
  dispatch,
  selectorState,
  previousStep,
  nextStep,
}: Props) => {
  return (
    <LayoutSelectorDesktop
      stepActive={2}
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('preferences.tus_preferencias')}>
      <MainFormContainer>
        <SelectorCaracteristicas
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
        />
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorDesktop>
  )
}

export default FormPreferencias
