import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import React, { useCallback, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import SearchResults from '../components/FunnelSelector/Common/SearchResults'
import TusDatos from '../components/FunnelSelector/Common/TusDatos'
import FormPreferencias from '../components/FunnelSelector/Desktop/FormPreferencias'
import Medidas from '../components/FunnelSelector/Desktop/Medidas'
import FormAncho from '../components/FunnelSelector/Mobile/FormAncho'
import FormCodigoVelocidad from '../components/FunnelSelector/Mobile/FormCodigoVelocidad'
import FormEstacion from '../components/FunnelSelector/Mobile/FormEstacion'
import FormIndiceCarga from '../components/FunnelSelector/Mobile/FormIndiceCarga'
import FormLlanta from '../components/FunnelSelector/Mobile/FormLlanta'
import FormMarcas from '../components/FunnelSelector/Mobile/FormMarcas'
import FormRunFlat from '../components/FunnelSelector/Mobile/FormRunFlat'
import FormSerie from '../components/FunnelSelector/Mobile/FormSerie'
import Step, {
  findNext,
  findPrevious,
  InSkipInfo,
  StepDetails,
  stepFromRoute,
} from '../components/FunnelSelector/SelectorSteps'
import Layout from '../components/Layout'
import { reportStep } from '../context/actions/selectorActions'
import { IAppState } from '../context/context'
import { SelectorState } from '../context/reducers/selectorReducer'
import {
  selectIsRodi,
  selectIsRodimotor,
  selectSelector,
  selectSkipInfo,
} from '../context/selectors/selectorSelectors'
import Size from '../utils/media-queries-setup'

interface Props {
  selectorState: SelectorState
  dispatch: (action: unknown) => void
  skipInfo: InSkipInfo
}
interface ContentsProps extends Props {
  device: 'mobile' | 'desktop'
}

const SelectorContents = ({
  device,
  selectorState,
  dispatch,
  skipInfo,
}: ContentsProps) => {
  let totalSteps = 9
  const path =
    typeof document !== 'undefined' ? document.location.pathname : '/'

  let preventRender = typeof window === 'undefined'
  // Null checking because static building requires it
  const requestedStep = useMemo(() => stepFromRoute(path), [path])
  if (!requestedStep) {
    typeof window !== 'undefined' && navigate('/', { replace: true })
    preventRender = true
  }

  const isRodi = useSelector(selectIsRodi)
  const isRodimotor = useSelector(selectIsRodimotor)
  // The route must match the device being used, or otherwise we
  // redirect them to the correct route
  preventRender = useMemo(() => {
    totalSteps = isRodimotor || isRodi ? 8 : 9

    if (preventRender) return true
    if (
      !requestedStep.routes[device] ||
      requestedStep.routes[device] !== path ||
      !requestedStep.isLoadable(selectorState, skipInfo)
    ) {
      const newStep = findPrevious(requestedStep.step, device, skipInfo)
      navigate(newStep.routes[device], { replace: true })
      return true
    }
    // Users may not open a skipped step
    if (requestedStep.skip && requestedStep.skip(skipInfo, device)) {
      const next = findNext(requestedStep.step, device, skipInfo)
      navigate(next.routes[device], { replace: requestedStep.replace })
      preventRender = true
      return true
    }
    return false
  }, [device, requestedStep, skipInfo])
  // And here we avoid rendering while the redirection is happening
  if (
    !preventRender &&
    (!requestedStep.routes[device] || requestedStep.routes[device] !== path)
  ) {
    preventRender = true
  }

  const nextStep = useCallback(() => {
    const next = findNext(requestedStep.step, device, skipInfo)
    dispatch(reportStep(StepDetails[requestedStep.step], device))
    navigate(next.routes[device], { replace: requestedStep.replace })
  }, [requestedStep, dispatch, device, skipInfo, selectorState])

  const previousStep = useCallback(
    (useBrowserHistory = true) => {
      if (
        useBrowserHistory &&
        typeof history !== 'undefined' &&
        history.length > 1
      ) {
        history.back()
        return
      }
      const prev = findPrevious(requestedStep.step, device, skipInfo)
      // console.log('Previous', requestedStep, 'Prev', prev)
      navigate(prev.routes[device])
    },
    [requestedStep, dispatch, device, skipInfo]
  )
  // console.log('rendering', preventRender, device, requestedStep, skipInfo)
  if (preventRender) {
    return null
  }

  return (
    <Layout seoData={null} onGoBack={previousStep} selector={true}>
      <Router>
        <Medidas path={StepDetails[Step.INITIAL].routes.desktop} />
        <FormAncho
          path={StepDetails[Step.SELECT_ANCHO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormSerie
          path={StepDetails[Step.SELECT_SERIE].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormLlanta
          path={StepDetails[Step.SELECT_LLANTA].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormIndiceCarga
          path={StepDetails[Step.SELECT_CARGA].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormCodigoVelocidad
          path={StepDetails[Step.SELECT_VELOCIDAD].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormPreferencias
          path={StepDetails[Step.SELECT_ESTACION].routes.desktop}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormEstacion
          path={StepDetails[Step.SELECT_ESTACION].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <FormRunFlat
          path={StepDetails[Step.SELECT_RUNFLAT].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
        />
        <FormMarcas
          path={StepDetails[Step.SELECT_MARCAS].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <TusDatos
          device={device}
          path={StepDetails[Step.INSERT_DATOS_LEAD].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorState={selectorState}
          totalSteps={totalSteps}
        />
        <SearchResults
          path={`${StepDetails[Step.SHOW_RESULTADOS].routes.desktop}`}
          nextStep={nextStep}
          previousStep={previousStep}
          selectorState={selectorState}
          page="resultados"
        />
        <SearchResults
          path={`${StepDetails[Step.REGISTER].routes.desktop}`}
          nextStep={nextStep}
          previousStep={previousStep}
          selectorState={selectorState}
          page="registro"
        />
        <SearchResults
          path={`${StepDetails[Step.SHOW_ALL_RESULTADOS].routes.desktop}`}
          nextStep={nextStep}
          previousStep={previousStep}
          selectorState={selectorState}
          page="todos"
        />
      </Router>
    </Layout>
  )
}

const Selector = (props: Props) => (
  <Size.Matcher
    mobile={<SelectorContents device="mobile" {...props} />}
    desktop={<SelectorContents device="desktop" {...props} />}
  />
)

const SelectorPage = connect((state: IAppState) => ({
  selectorState: selectSelector(state),
  skipInfo: selectSkipInfo(state),
}))(Selector)

export default SelectorPage
